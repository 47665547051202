// SubscriptionCard.tsx
import React, { useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  styled,
  CircularProgress,
  Grid,
  Box
} from "@mui/material";
import { getCheckoutUrl } from "../services/StripeService";
import { AuthContext } from "../context/AuthProvider";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

interface SubscriptionCardProps {
  name: string;
  price: string;
  description: string;
  priceId: string | undefined;
}

const HoverCard = styled(Card)(({ theme }) => ({
  transition: "transform 0.3s",
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  name,
  price,
  description,
  priceId,
}) => {
  const [isGetButtonLoading, setIsGetButtonLoading] = React.useState(false);
  const authContext = useContext(AuthContext);
  const { app } = authContext || {};
  const theme = useTheme();
  const navigate = useNavigate();

  const handleGetPlanClick = () => {
    setIsGetButtonLoading(true);


    if (priceId && authContext?.user) {
      getPlanByPriceId(priceId);
      } else if (priceId) {
        navigate("/signup");
      } else if (!priceId && authContext?.user) {
        navigate("/download");
      } else {
        navigate("/signup");
      }

  };

  const getPlanByPriceId = async (priceId: string) => {
    if (!app) return;
    const checkoutUrl = await getCheckoutUrl(app, priceId);
    window.location.href = checkoutUrl;
  };

  return (
    <HoverCard>
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" component="div">
                {name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="text.secondary">
                <span style={{ color: priceId ? theme.palette.primary.main : 'inherit' }}>
                  {price}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: priceId ? 'inherit' : '0.875rem' }}
              >
                {description}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleGetPlanClick}
            disabled={isGetButtonLoading}
            startIcon={
              isGetButtonLoading ? (
                <CircularProgress size={20} color="primary" />
              ) : null
            }
          >
            {priceId ? `Get ${name}` : 'Continue without Subscription'}
          </Button>
        </Box>
      </CardContent>
    </HoverCard>
  );
};

export default SubscriptionCard;