import React, { useContext } from "react";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";
import { Download } from "@mui/icons-material";

const NavBar: React.FC = () => {
  const authContext = useContext(AuthContext);
  const { loginUser, logOut, loading, user } = authContext || {};

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Button component={RouterLink} to="/" sx={{ mr: 2 }}>
              SonaScript
            </Button>
            <Button component={RouterLink} to="/download" sx={{ mr: 2 }}>
              Download SonaScript
            </Button>
          </Box>
          <Box>
            {user && logOut ? (
              <>
                <Button component={RouterLink} to="/profile" sx={{ mr: 2 }}>
                  My Profile
                </Button>
                <Button onClick={() => logOut()}>
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Button component={RouterLink} to="/login" sx={{ mr: 2 }}>
                  Login
                </Button>
                <Button component={RouterLink} to="/signup">
                  Sign-Up 
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;