import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import SubscriptionsList from './SubscriptionsList';

const FeatureSection: React.FC<{ title: string, description: string, imageOnRight: boolean, imageSrc: string }> = ({ title, description, imageOnRight, imageSrc }) => (
  <Paper elevation={3} sx={{ p: 3, my: 4, display: 'flex', flexDirection: 'column' }}>
    <Grid container spacing={4} alignItems="center" direction={imageOnRight ? 'row' : 'row-reverse'}>
      <Grid item xs={12} md={6}>
        <Typography variant="h5" gutterBottom>{title}</Typography>
        <Typography variant="body1">{description}</Typography>
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          component="img"
          sx={{
            maxWidth: '100%',
            height: 'auto',
            objectFit: 'contain',
          }}
          alt={title}
          src={imageSrc}
        />
      </Grid>
    </Grid>
  </Paper>
);

const SonaScriptLandingPage: React.FC = () => {
  const features = [
    {
      title: "Flexible Transcription Options",
      description: "Choose between high-quality online transcription using OpenAI key and local mode transcription using downloaded models (requires more RAM).",
      imageSrc: "/models.gif"
    },
    {
      title: "Comprehensive Transcription History",
      description: "Your transcriptions are locally stored and accessible anytime you want. Modify the text on the fly, re-record your transcriptions, and add new details with ease. Your work is always at your fingertips, ready for review and refinement.",
      imageSrc:"/history.gif"
    },
    {
      title: "Text Transformation",
      description: "Transform your transcribed input using LLM models (only online mode supported, using your own OpenAI key).",
      imageSrc: "/api/placeholder/600/400"
    },
  ];

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h2" component="h1" gutterBottom align="center">
          Welcome to SonaScript
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          Effortless Speech-to-Text, Anytime, Anywhere
        </Typography>
        <Box my={6}>
          <Typography variant="h4" component="h2" gutterBottom align="center">
            Key Features
          </Typography>
          {features.map((feature, index) => (
            <FeatureSection
              key={index}
              title={feature.title}
              description={feature.description}
              imageOnRight={index % 2 === 0}
              imageSrc={feature.imageSrc}
            />
          ))}
        </Box>
        <Box my={6}>
          <Typography variant="h4" component="h2" gutterBottom align="center">
            Choose Your Plan
          </Typography>
          <SubscriptionsList />
        </Box>
      </Box>
    </Container>
  );
};

export default SonaScriptLandingPage;