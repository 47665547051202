import React from "react";
import { Container, Grid, Paper } from "@mui/material";
import SubscriptionCard from "./SubscriptionCard";

const SubscriptionsList: React.FC = () => {
    const planOptions = [
        {
            id: 0,
            name: 'Coffee Subscription',
            price: '$4.99/month',
            description: "Get all features and keep us buzzing with our coffee subscription. With this plan, SonaScript will be available for you 24/7. You're brew-tiful!",
            priceId: 'price_1Q78bpI50WvmQmIHdZtuebVq'
        },
        {
            id: 1,
            name: 'Continue without Subscription',
            price: '$0.00/month',
            description: "SonaScript offers a full-featured trial with no expiration date. However, without its morning coffee, SonaScript can only work for 2 hours before needing to rest. It might be a bit grumpy if you ask it to work without its morning coffee. If you're okay with that, feel free to continue without a subscription. (You still need an account, though!) Otherwise, consider subscribing to support SonaScript and keep it alert whenever you need it."
        },
    ];

    return (

        <Paper elevation={3} sx={{ p: 3, my: 4, display: 'flex', flexDirection: 'column' }}>
        <Container>
            <Grid container spacing={4} justifyContent="center">
                {planOptions.map((plan) => (
                    <Grid item xs={12} sm={6} key={plan.id}>
                        <SubscriptionCard 
                            name={plan.name}
                            price={plan.price}
                            description={plan.description}
                            priceId={plan?.priceId}
                        />
                    </Grid>
                ))}
            </Grid>
            </Container>
        </Paper>
    );
};

export default SubscriptionsList;