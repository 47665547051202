// src/App.tsx
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AuthProvider from "./context/AuthProvider";
import createTheme from '@mui/material/styles/createTheme';
import NavBar from "./components/NavbarComponent";
import PrivateRoute from "./components/PrivateRoute";
import darkThemeOptions from "./Themes";
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import Profile from "./components/ProfilePage";
import Download from "./components/DownloadPage";
import SonaScriptLandingPage from './components/SonaScriptLandingPage';
import LoginRoute from './components/LoginRoute';
import Login from './components/LoginComponent';
import SignUp from './components/SignUpComponent';


const darkTheme = createTheme(darkThemeOptions);


const App: React.FC = () => {
  return (
    <Router>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline/>
        <AuthProvider>
        <NavBar />
        <Routes>
          <Route path="/" element={<SonaScriptLandingPage />} />
          <Route path="/login" element={<LoginRoute><Login /></LoginRoute>} />
          <Route path="/signup" element={<LoginRoute><SignUp /></LoginRoute>} />
          <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/download" element={<Download />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    </AuthProvider>
        </ThemeProvider>
    </Router>
  );
};

export default App;