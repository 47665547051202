// src/components/LoginRoute.tsx
import React, {useContext} from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthProvider';

interface LoginRouteProps {
  children: React.ReactNode;
}

const LoginRoute: React.FC<LoginRouteProps> = ({ children }) => {
  const authContext = useContext(AuthContext);
  const {  user } = authContext || {}; 
  return user ? <Navigate to="/" replace /> : <>{children}</>;
};

export default LoginRoute;
