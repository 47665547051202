import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Device } from '../types/types';

interface DeviceCardProps {
  device: Device;
  onDelete: (deviceId: string) => void;
}

const DeviceCard: React.FC<DeviceCardProps> = ({ device, onDelete }) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmDelete = () => {
    onDelete(device.device_id);
    handleCloseConfirmDialog();
  };


  return (
    <>
      <Grid item xs={12} sm={3} md={2}>
      <Card variant="outlined">
        <CardContent>
          <div>
            <Typography variant="h6" component="div">
              {device.device_name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
            </Typography>
          </div>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleOpenConfirmDialog}
          >
            Delete
          </Button>
        </CardContent>
        </Card>
        </Grid>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Device Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want remove "{device.device_name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeviceCard;