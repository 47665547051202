import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";

import {
  CircularProgress,
  TextField,
  Button,
  Box,
  Container,
  Typography,
  Grid,
  Alert,
  Card,
  CardContent,
} from "@mui/material";

const Login: React.FC = () => {
  const authContext = useContext(AuthContext);
  const { loginUser, signInWithGoogle, loading, user } = authContext || {}; // Add null check
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  if (loading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        style={{ minHeight: "100vh", marginTop: "200px" }}
      >
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as typeof event.target & {
      email: { value: string };
      password: { value: string };
    };
    const { email, password } = target;
    if (loginUser) {
      loginUser(email.value, password.value)
        .then((result) => {
          console.log(result);
          navigate("/");
        })
        .catch((error) => {
          console.log("error in login");
          console.log(JSON.stringify(error));
          if (error.code) {
            //TODO: check if these error codes are correct, suggested by copilot
            if (error.code.includes("auth/user-not-found")) {
              setError("User not found");
            } else if (error.code.includes("auth/wrong-password")) {
              setError("Invalid password");
            } else if (error.code.includes("auth/invalid-credential")) {
              setError("Invalid login or password");
            } else {
              setError(error.code);
            }
          }
        });
    }
    event.currentTarget.reset();
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
          
              
          </Grid>



          
          <Grid item xs={4}></Grid>


          {error && (
            <Grid item xs={12}>
              <Alert severity="error" sx={{ width: "100%", mt: 2 }}>
                {error}</Alert>
            </Grid>
            )}
              
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box
                  component="form"
                  onSubmit={handleFormSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />

                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Login
                      </Button>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center">
                      <Button
                        startIcon={
                          <img
                            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                            alt="Google sign-in"
                            style={{ width: "20px", height: "20px" }}
                          />
                        }
                        onClick={signInWithGoogle}
                        variant="outlined"
                      >
                        Login with Google
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Login;
