import React, { useContext, useState } from "react";
import { AuthContext } from "../context/AuthProvider";
import {
  Box,
  Card,
  Paper,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  Button,
  CircularProgress
} from "@mui/material";
import { SubscriptionResponse, Device } from "../types/types";
import { useTheme } from "@mui/material/styles";
import { getPortalUrl } from "../services/StripeService";
import DeviceCard from "./DeviceCard";

interface SubscriptionProps {
  subscription: SubscriptionResponse;
  onDeleteDevice: (deviceId: string) => Promise<void>;
}

const Subscription: React.FC<SubscriptionProps> = ({ subscription, onDeleteDevice }) => {
  const authContext = useContext(AuthContext);
  const { app } = authContext || {};
  const theme = useTheme();
  const [isLoadingManageSubscription, setIsLoadingManageSubscription] = useState(false);

  if (!subscription || !subscription.subscription_data) {
    return null;
  }

  const {
    device_count,
    device_limit,
    subscription_data: {
      plan,
      status,
      current_period_start,
      current_period_end,
      stripe_link
    },
    devices
  } = subscription;

  const periodStartFormatted = new Date(current_period_start * 1000).toLocaleDateString();
  const periodEndFormatted = new Date(current_period_end * 1000).toLocaleDateString();
  const daysLeft = Math.ceil((current_period_end * 1000 - Date.now()) / (1000 * 60 * 60 * 24));

  const manageSubscription = async () => {
    if (!app) return;
    setIsLoadingManageSubscription(true);
    if (stripe_link) {
      window.location.href = stripe_link;
    } else {
      const portalUrl = await getPortalUrl(app);
      window.location.href = portalUrl;
    }
  }

  const handleDeleteDevice = async (deviceId: string) => {
    try {
      await onDeleteDevice(deviceId);
    } catch (error) {
      console.error("Error deleting device:", error);
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} />
        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader
              title={`Subscription Plan: ${plan}`}
              subheader={`Devices: ${device_count} / ${device_limit}`}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Current Period: {periodStartFormatted} - {periodEndFormatted}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Status: {status.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Your subscription will be renewed automatically in {daysLeft} days
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={manageSubscription}
                    disabled={isLoadingManageSubscription}
                    startIcon={isLoadingManageSubscription ? <CircularProgress size={20} color="primary" /> : null}
                  >
                    Manage Subscription
                  </Button>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} />        
        {devices.length > 0 && (
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 3, my: 4, display: 'flex', flexDirection: 'column' }}>
              <Typography >Registered Devices</Typography>
              <Grid container spacing={1}>
                {devices.map((device: Device) => (
                  <DeviceCard 
                    key={device.device_id} 
                    device={device} 
                    onDelete={handleDeleteDevice} 
                  />
                ))}
              </Grid>
           </Paper> 
          </Grid>
          )}
      </Grid>
    </Container>
  );
};

export default Subscription;