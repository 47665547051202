import React, { useContext, useState, FormEvent } from "react";
import { AuthContext } from "../context/AuthProvider";
import { updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  CircularProgress,
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  Alert
} from "@mui/material";

const SignUp: React.FC = () => {
  const authContext = useContext(AuthContext);
  const { createUser, signInWithGoogle, loading, user } = authContext || {};
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (user) {
    navigate("/");
    return null; // Prevent component from rendering anything else after navigating
  }

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      name: { value: string };
      email: { value: string };
      password: { value: string };
    };
    const { name, email, password } = target;
    if (!createUser) {
      return;
    }
    createUser(email.value, password.value)
      .then((result) => {
        if (result.user) {
          updateProfile(result.user, {
            displayName: name.value,
          }).then(() => {
            navigate("/");
          });
        }
      })
      .catch((error) => {
        console.error(error);
        let errorCode = error.code;
        if (errorCode.includes("auth/weak-password")) {
          setError("Password is too weak");
        } else if (errorCode.includes("auth/email-already-in-use")) {
          setError("Email already in use");
        } else if (errorCode.includes("auth/invalid-email")) {
          setError("Invalid email");
        } else if (errorCode.includes("auth/operation-not-allowed")) {
          setError("Operation not allowed");
        } else {
          setError(error.message.replace("Firebase: ", ""));
        }
      });

    e.currentTarget.reset();
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Alert severity="error" sx={{ width: "100%", mt: 2 }}>
                {error}</Alert>
            </Grid>
            )}
          <Grid item xs={4}></Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box
                  component="form"
                  onSubmit={handleFormSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Sign-Up
                      </Button>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center">
                      <Button
                        startIcon={
                          <img
                            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                            alt="Google sign-in"
                            style={{ width: "20px", height: "20px" }}
                          />
                        }
                        onClick={signInWithGoogle}
                        variant="outlined"
                      >
                        Sign-Up with Google
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SignUp;
