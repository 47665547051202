import React, { useState } from "react";
import { Box, Button, Card, CardContent, Collapse, Container, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Download: React.FC = () => {
    const [expandedVersion, setExpandedVersion] = useState(false);

    const latestRelease = {
        version: "1.0.0",
        date: "2024-06-13",
        newFeatures: ["Feature 1", "Feature 2"],
        fixedBugs: ["Bug fix 1", "Bug fix 2"]
    };

    const olderVersions = [
        { version: "0.9.0", link: "/download/0.9.0", note: "Stable version" },
        { version: "0.8.0", link: "/download/0.8.0", note: "Beta release" }
    ];

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Download Latest Release
            </Typography>
            <Card>
                <CardContent>
                    <Typography variant="h5">
                        Version {latestRelease.version} - {latestRelease.date}
                    </Typography>
                    <Button variant="outlined" color="primary" href="/download/latest">
                        Download
                    </Button>
                    <Typography variant="subtitle1" style={{ marginTop: '10px' }}>
                        Change Log
                    </Typography>
                    <Button
                        onClick={() => setExpandedVersion(!expandedVersion)}
                        aria-expanded={expandedVersion}
                        aria-label="show more"
                        variant="outlined"
                        color="secondary"
                    >
                        {expandedVersion ? "Hide" : "Show"} Details
                    </Button>
                    <Collapse in={expandedVersion} timeout="auto" unmountOnExit>
                        <Box mt={2}>
                            <Typography variant="subtitle2">New Features</Typography>
                            <ul>
                                {latestRelease.newFeatures.map((feature, index) => (
                                    <li key={index}>{feature}</li>
                                ))}
                            </ul>
                            <Typography variant="subtitle2">Fixed Bugs</Typography>
                            <ul>
                                {latestRelease.fixedBugs.map((bug, index) => (
                                    <li key={index}>{bug}</li>
                                ))}
                            </ul>
                        </Box>
                    </Collapse>
                </CardContent>
            </Card>
        </Container>
    );
};

export default Download;
