import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";
import { Box, Container, Grid, Typography } from "@mui/material";
import Subscription from "./SubscriptionComponent";
import SubscriptionsList from "./SubscriptionsList";
import Skeleton from "@mui/material/Skeleton";

const Profile: React.FC = () => {
  const authContext = useContext(AuthContext);
  const { user, getSubscriptionStatus, app, loading } = authContext || {};
  const navigate = useNavigate();
  const [subscription, setSubscription] = React.useState(null);
  const [loadingSubscription, setLoadingSubscription] = React.useState(true);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    if (user && getSubscriptionStatus && app) {
      setLoadingSubscription(true);
      getSubscriptionStatus(app).then((subscription) => {
        console.log("subscription:");
        console.log(subscription);
        setSubscription(subscription);
        setLoadingSubscription(false);
      });
    }
  }, [user, navigate]);

  const handleDeleteDevice = async (deviceId: string) => {
    console.log("Deleting device with id: ", deviceId);
  }

  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <Grid item xs={12}>
            <Typography variant="h6">Welcome {user?.email}</Typography>
          </Grid>

          {!loading && !loadingSubscription ? (
            <Grid item xs={12}>
              {subscription ? (
                <Subscription subscription={subscription} onDeleteDevice={handleDeleteDevice} />
              ) : (
                <>
                  <Typography variant="h6">No subscription found</Typography>
                  <SubscriptionsList />
                </>
              )}
            </Grid>
          ) : (
            <Box sx={{ width: 300 }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default Profile;
